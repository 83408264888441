import { useEffect, useState } from "react"

export const useDebounceWithLoader = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        const t = setTimeout(() => {
            setDebouncedValue(value)
            setIsLoading(false)
        }, delay)

        return () => {
            clearTimeout(t)
            setIsLoading(false)
        }
    }, [value, delay])
    return { debouncedValue, isLoading }
}
